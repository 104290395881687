@font-face {
    font-family: 'twklausannepan-Regular';
    src: url('../fonts/twklausannepan-300-webfont.woff') format('woff'),
         url('../fonts/twklausannepan-300-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'twklausannepan-Medium';
    src: url('../fonts/twklausannepan-400-webfont.woff') format('woff'),
         url('../fonts/twklausannepan-400-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'twklausannepan-Bold';
    src: url('../fonts/twklausannepan-500-webfont.woff') format('woff'),
         url('../fonts/twklausannepan-500-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'twklausannepan-extrabold';
    src: url('../fonts/twklausannepan-600-webfont.woff') format('woff'),
         url('../fonts/twklausannepan-600-webfont.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'twklausannepan-Regular';
}

@import './login.scss';
@import './sidebar.scss';
@import './formItems.scss';
@import './members.scss';
@import './events.scss';
@import './general.scss';
@import './fullpageLayout.scss';
@import './systemSettings.scss';
@import './responsive.scss';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}