.top_filter {
    display: flex;
    align-items: center;
}

.filter_right {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
}

.footer_dropdown{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 26px;
}
.footer_logout{
    border: unset;
    padding: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    box-shadow: unset;
    background-color: unset;
    justify-content: flex-start;
    color: #0D1019;
    font-size: 14px;
    line-height: 20px;

    span {
        display: flex;
        gap: 8px;
    }
    
    &:hover{
        background-color: transparent !important;
        color: #0D1019 !important;
    }
}

.main_logout {
    margin-left: -2px;
    height: 26px;
}

.event_confirmation{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
    color: #0D1019;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    font-family: 'twklausannepan-Regular';
    .event_confirmation_checkbox{
        display: flex;
        align-items: flex-start;
        gap: 4px;
        input{
            margin-top: 4px;
        }
    }
    .checked{
        color: #727379;
        text-decoration: line-through;
    }
    .date_tag{
        border-radius: 44px;
        border: 1px solid #E9CFFF;
        padding: 5px 15px;
    }
}

.filter_btn {
    display: flex;
    align-items: center;

    button {
        height: 40px;
        border: 0;
        box-shadow: none;

        span {
            color: #0D1019;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'twklausannepan-Medium';
        }
    }

    img {
        display: block;
    }
}

.search_filter {
    width: 265px;

    .ant-input-outlined {
        height: 40px;

        input {
            &::placeholder {
                opacity: 1;
                color: #727379;
            }
        }
    }
}

.filter_div {
    display: flex;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid #E4E4E5;

    .reset_btn {
        margin-left: auto;
        button{
            background-color: transparent;
            height: 36px;
            padding: 10px 10px 8px 8px;
            color: #949599;
            border-radius: 8px;
            line-height: 16px;
            
            .anticon {
                svg {
                    path {
                        fill: #949599;
                    }
                }
            }

            &:hover {
                border: 1px solid #E4E4E5;
                color: #050622 !important;
                background: #E4E4E5 !important; 
            }
            span{
                color: #949599;
            }
        }
    }

    .filter_radio {
        margin-right: 16px;

        .ant-radio-group {
            height: 28px;
            .ant-radio-button-wrapper {
                height: 28px;

                span {
                    height: 28px;
                    line-height: 28px;
                }
            }
        }
    }

    .ant-select-single {
        height: 28px;

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            color: #0D1019;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            font-family: 'twklausannepan-Bold';
        }
    }

    .filter_search {
        gap: 8px;
        display: flex;

        .ant-select {
            &:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
                border-color: #E4E4E5;
            }
            .ant-select-selection-item{
                display: flex;
                justify-content: center;
                align-items: center;
                .ant-image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 17px;
                        height: 17px !important;
                        border-radius: 5px !important;
                    }
                }
            }
            .ant-select-selector {
                border: 1px solid #E4E4E5;
                border-radius: 20px;
                position: relative;
                z-index: 1;
                background-color: transparent;
            }

            &.ant-select-multiple,
            .ant-select-selection-placeholder {
                color: #0D1019;
                font-family: 'twklausannepan-Bold';
                font-size: 12px;
            }
        }
    }
}

.filter_search {
    .ant-picker .ant-picker-input >input::placeholder {
        color: #0D1019;
        font-family: 'twklausannepan-Bold';
        font-size: 12px;
    }

    .filter_date {
        border: 1px solid #E4E4E5;
        border-radius: 20px;

        input {
            color: #0D1019;
            font-family: 'twklausannepan-Bold';
            font-size: 12px;
        }
    }
}

.ant-input-number .ant-input-number-input::placeholder,
.ant-picker .ant-picker-input >input::placeholder {
    color: #727379;
    font-weight: 300;
    font-family: "twklausannepan-Regular";
    font-size: 14px;
}

.ant-select:hover .ant-select-arrow:not(:last-child) {
    opacity: 1;
}

.ant-input-outlined {
    padding: 10px 16px;
    border-radius: 36px;
    border: 1px solid #0D1019;
    
    &:hover {
        border: 1px solid #0D1019;
    }
    &:focus,
    &:active,
    &:focus-within{
        box-shadow: unset;
        border: 1px solid #0D1019;
    }

    .ant-input {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        color: #727379;
    }
}

.icon_dark_btn {
    background-color: #0D1019;
    border-radius: 24px;
    padding: 10px 20px;
    height: 40px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: 'twklausannepan-Medium';
    border: 1px solid #0D1019;

    &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: #343740;
        border: 1px solid #343740;
        color: #fff;
    }

    &:disabled {
        border: 1px solid #949599;
        background-color: #949599;
        color: #E4E4E5;
    }
}

.icon_white_btn {
    background-color: #fff;
    border-radius: 24px;
    padding: 10px 20px;
    height: 40px;
    color: #0D1019;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: 'twklausannepan-Medium';
    border: 1px solid #E4E4E5;

    &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: #fff;
        border: 1px solid #BCBDBF;
        color: #0D1019;
    }

    &:disabled {
        border: 1px solid #E4E4E5;
        background-color: #fff;
        color: #9797AD;
    }
}

.dn_right {
    display: flex;
    align-items: center;
    gap: 8px;
}

button {
    img {
        display: block;
    }
}

.ant-table-wrapper {
    .ant-table {
        border: 1px solid #E4E4E5;
        border-radius: 12px;
        overflow: hidden;
    }

    .ant-table-thead {
        >tr {
            >th {
                background-color: #FCFCFC;
                color: #727379;
                font-size: 14px;
                font-weight: 300;
                font-family: 'twklausannepan-Regular';
                line-height: 24px;
                padding: 12px 16px;
                border-bottom: 1px solid #E4E4E5;

                &::before {
                    display: none !important;
                }

                &:first-child {
                    border-start-start-radius: 16px !important
                }

                &:last-child {
                    border-start-end-radius: 16px !important
                }
            }
        }
    }

    .ant-table-tbody {
        >tr {
            cursor: pointer;
            >td {
                background-color: #fff;
                color: #050622;
                font-size: 14px;
                font-weight: 300;
                font-family: 'twklausannepan-Regular';
                line-height: 20px;
                padding: 10px 16px;
                border-bottom: 1px solid #E4E4E5;

                &::before {
                    display: none !important;
                }

                &:first-child {
                    border-start-start-radius: 16px !important
                }

                &:last-child {
                    border-start-end-radius: 16px !important
                }
            }

            &.ant-table-row-selected {
                >td {
                    &:first-child {
                        border-start-start-radius: 0 !important
                    }
    
                    &:last-child {
                        border-start-end-radius: 0 !important
                    }
                }
            }

            &:last-child {
                >td {
                    border-bottom: 0;
                }
            }
        }
    }
}

.table_image {
    .ant-image {
        img {
            border-radius: 8px;
            display: block;
        }
    }
}

.table_with_image {
    display: flex;
    align-items: center;

    .ant-image {
        margin-right: 8px;
        img {
            border-radius: 8px;
            object-fit: cover;
        }
    }

    .ant-avatar {
        margin-right: 8px;
    }

    .main_text {
        display: block;
        color: #0D1019;
        font-size: 14px;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        line-height: 17px;
    }

    .email_text {
        display: block;
        color: #727379;
        font-size: 10px;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        line-height: 15px;
    }
}

.country_text {
    color: #727379;
}

.defaultTag {
    padding: 2px 0 1px;
    border-radius: 80px;
    width: 93px;
    text-align: center;
    font-family: 'twklausannepan-Regular';
    font-weight: 300;
    line-height: 16px;
    text-transform: capitalize;

    &.pink_tag {
        background-color: #FFD1EA;
        border-color: #FFD1EA;
    }

    &.orange_tag {
        background-color: #F9CEA9;
        border-color: #F9CEA9;
    }

    &.dark_pink_tag {
        background-color: #E9CFFF;
        border-color: #E9CFFF;
    }

    &.green_tag {
        background-color: #CEF4B8;
        border-color: #CEF4B8;
    }    

    &.dark_orange_tag {
        background-color: #FFD96C;
        border-color: #FFD96C;
    }

    &.undisclosed {
        background-color: #BBE8FB;
        border-color: #BBE8FB;
    }
}

.table_text_right {
    text-align: right;
    width: 100%;
    display: inline-block;
}

.ant-avatar {
    &.ant-avatar-square {
        border-radius: 8px;
    }
}

.ant-card-meta-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-card-meta-title {
        color: #0D1019;
        font-family: 'twklausannepan-Bold';
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }

    .ant-card-meta-description {
        .ant-tag {
            border: 1px solid #C287F4;
            border-radius: 44px;
            background-color: #fff;
            padding: 4px 12px;
            color: #727379;
            font-size: 12px;
            line-height: 16px;
            font-family: 'twklausannepan-Bold';
            font-weight: 500;

            span {
                color: #0D1019;
            }
        }
    }
}


.divider{
    border: 1px solid #E4E4E5;
    width: calc(100% - 24px);
    height: 1px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.input_select{
    .ant-form-item-control-input-content,
    .ant-select.ant-select-in-form-item{
        height: 40px;
    }
    .ant-select-outlined {
        .ant-select-selector {
            border: 1px solid #0D1019;
            border-radius: 20px;
            padding: 10px 16px;
            height: 40px !important;
        }
        
        &:hover {
            .ant-select-selector {
                border-color: #0D1019 !important;
            }
        }
    }
}

.input_phone{
    .react-tel-input {
        .form-control{
            font-size: 14px;
            border: 1px solid #0d1019;
            border-radius: 20px;
            line-height: 25px;
            height: 40px;
            width: 100%;
        }
        .flag-dropdown{
            background-color: #fff;
            border: 1px solid #0d1019;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-right: unset;
            padding-left: 10px;
            &.open {
                z-index: 1;
            }
            .selected-flag{
                &:hover,
                &:focus{
                    background-color: transparent;
                }
            }
        }
    }
}

.ant-tabs {
    &.ant-tabs-left {
        .ant-tabs-nav {
            width: 178px;
            display: flex;
            flex: none;
            align-items: flex-start;

            .ant-tabs-nav-wrap {
                flex: none;
                align-self: auto;

                .ant-tabs-nav-list {
                    display: inline-flex;
                    align-items: flex-start;
                }
            }

            .ant-tabs-ink-bar {
                display: none;
            }

            .ant-tabs-tab {
                display: inline;
                padding: 10px 12px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: 'twklausannepan-Medium';

                +.ant-tabs-tab {
                    margin-top: 4px;
                }

                .ant-tabs-tab-btn {
                    color: #484B53;
                }

                &.ant-tabs-tab-active {
                    background-color: #0D1019;
                    border-radius: 24px;                    
                    
                    .ant-tabs-tab-btn {
                        color: #fff;
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            border-left: 0;

            .ant-tabs-tabpane {
                padding-left: 20px;
            }
        }
    }
}

.ant-card {
    .ant-card-bordered {
        border: 1px solid #E4E4E5;
        border-radius: 20px;
    }
}

.leftTabCard {
    .cardHead {
        margin: 0 -24px;
        border-bottom: 1px solid #E4E4E5;
        padding: 0 24px 24px 24px;
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        h3 {
            color: #0D1019;
            font-size: 24px;
            line-height: 28px;
            font-weight: 300;
            font-family: 'twklausannepan-Regular';
            margin-bottom: 4px;
        }

        p {
            color: #484B53;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            font-family: 'twklausannepan-Regular';
            margin-bottom: 0;
        }

        .filter_div {
            margin-top: 16px;
            width: 100%;
        }
    }

    .leftCardDiv {
        width: 375px;

        .ant-form-item {
            margin-bottom: 0;
        }

        +.leftCardDiv {    
            .ant-form-item {
                margin-top: 8px;
            }
        }
    }

    .cardContent {
        

        h4 {
            color: #0D1019;
            font-size: 16px;
            line-height: 20px;
            font-family: 'twklausannepan-Bold';
            font-weight: 500;
        }
    }
}

.green_outline_tag  {
    border: 1px solid #3EA561;
    color: #3EA561;
}

.red_outline_tag {
    border: 1px solid #DF2E25;
    color: #DF2E25;
}

.unpublish_tag {
    border: 1px solid #727379;
    color: #727379;
}

.upload_modal_preview{
    .ant-image{
        img{
            object-fit: cover;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}

.fundingSource_radio{
    .ant-form-item-control{
        .ant-form-item-control-input{
            .ant-radio-group{
                height: 40px;
                background-color: #F1F2F2;
                border-radius: 24px;
                width: 100%;
                display: flex;
                .ant-radio-button-wrapper:not(:first-child)::before{
                    content: "";
                    background-color: unset;
                }
                .ant-radio-button-wrapper {
                    padding: 0 33px;
                    font-size: 12px;
                }
                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                    border-radius: 24px;
                    color: #fff;
                    background-color: #0D1019;
                    border: 0;
                    .ant-radio-button-checked{
                        background-color: #0D1019;
                        border: 0;
                        color: #fff;
                        border-radius: 24px;
                    }
                }
                .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked){
                    background-color: #F1F2F2;
                    border: 0;
                    color: #484B53;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: 'twklausannepan-Medium';
                }
                .ant-radio-button-wrapper{
                    height: 40px;
                    border-radius: 24px;
                    width: 50%;
                    span{
                        height: 40px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }
        }
    }
}


.filter_radio{
    .ant-radio-group{
        height: 40px;
        background-color: #F1F2F2;
        border-radius: 24px;
        .ant-radio-button-wrapper:not(:first-child)::before{
            content: "";
            background-color: unset;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
            border-radius: 24px;
            color: #fff;
            background-color: #0D1019;
            border: 1px solid #0D1019;
            .ant-radio-button-checked{
                background-color: #0D1019;
                border: 1px solid #0D1019;
                color: #fff;
                border-radius: 24px;
            }
        }
        .ant-radio-button-wrapper {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            font-family: 'twklausannepan-Bold';
        }
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked){
            background-color: #F1F2F2;
            border: 1px solid #F1F2F2;
            color: #484B53;
            
        }
        .ant-radio-button-wrapper{
            height: 40px;
            border-radius: 24px;
            span{
                height: 40px;
                line-height: 40px;
            }
        }
    }
}

.cancelBookingBar {
    background: #F1F2F2;
    padding: 19px 24px 19px 24px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.selectedMember{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'twklausannepan-Bold';
}
.eventsActionBtns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .cancelBtn{
        height: 40px;
        border-radius: 24px;
    }
}
.photoUploader {
    .ant-upload-drag{
        border: unset;
        .ant-upload-btn{
            height: 172px !important;
            background-color: #fff;
            border: 1px dashed #E4E4E5;
        }
    }
    .actionBtns{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        .green-btn{
            background-color: #949599;
            border-radius: 24px;
            padding: 10px 16px;
            border: 1px solid #949599;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'twklausannepan-Medium';
            color: #E4E4E5;
            &:hover{
                background-color: #949599;
                border: 1px solid #949599;
                color: #E4E4E5;
            }
        }
        .grey-btn{
            background-color: #fff;
            border-radius: 24px;
            padding: 10px 16px;
            border: 1px solid #E4E4E5;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'twklausannepan-Medium';
            color: #0D1019;
            &:hover{
                background-color: #fff;
                border: 1px solid #E4E4E5;
                color: #0D1019;
            }
        }
    }
    .close_popup{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: flex-start;
        img{
            width: 16px;
                height: 16px;
        }
    }
}

.table_type {
    border: 1px solid #0D1019;
    color: #0D1019;
}

.password_label{
    font-family: 'twklausannepan-Bold';
    font-weight: 500; 
    font-size: 16px;
}

.week_info_wrapper{
    .onDays_wrapper{
        .ant-col{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .flex-div{
            display: flex;
            gap: 12px;
            
        }
    }
}

.addBook_wrapper {
    display: flex;
    margin-top: 12px;
    .book_name{
        width: 347px;
        &:last-child{
            margin-bottom: 0px;
        }
        .ant-form-item{
            margin: 0;
        }
    }
}

.tasks_section{
    display: flex;
    flex-direction: column;
    .heading{
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        font-family: 'twklausannepan-Bold';
        color: #0D1019;
        margin-bottom: 2px !important;
    }
    .sub_heading{
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        color:#484B53;
    }
}

.addFieldButton{
    border: unset;
    box-shadow: unset;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-size: 14px;
    line-height: 21px;
    span{
        font-weight: 400;
        font-family: 'twklausannepan-Medium';
        color:#0D1019;
    }
    .darkIcon{
        svg {
            path{
                fill: #0D1019;
            }
        }
    }
}

.removeFieldButton{
    border: unset;
    box-shadow: unset;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    span{
        font-weight: 400;
        font-family: 'twklausannepan-Medium';
        color:#0D1019;
    }
}


.ant-modal{
    .ant-modal-content{
        padding: 0px;
        .eTableModal{
            .modal_header{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 24px;
                border-bottom: 1px solid #E4E4E5;
                .title{
                    color:#0D1019;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: 'twklausannepan-Bold';
                    display: flex;
                    img{
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                }
                .close_icon{
                    justify-content: center;
                    align-items: center;
                    display: flex;                
                    .ant-btn{
                        padding: 0px;
                        height: 20px;
                        width: 20px;
                    }
                }
            }
            .modal_content{
                padding: 24px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                border-bottom: 1px solid #E4E4E5;
                h4{
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 300;
                    font-family: "twklausannepan-Regular";
                    color: #0D1019;
                }
                p{
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 300;
                    font-family: 'twklausannepan-Regular';
                    color: #727379;

                    &.ant-upload-text {
                        color: #0D1019;
                        font-family: 'twklausannepan-Medium';
                        font-size: 14px;
                    }

                    &.ant-upload-hint {
                        color: #484B53;
                        font-size: 12px;
                    }
                }
            }
            .modal_footer{
                padding: 24px;
                display: flex;
                justify-content: flex-end;
                gap: 8px;
                .cancelBtn,
                .actionBtn{
                    border-radius: 24px;
                    padding: 10px 14px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    font-family: 'twklausannepan-Medium';
                    box-shadow: unset;
                }
                .dangerBtnColor{
                    background-color: #DF2E25;
                    border: 1px solid #DF2E25;
                    color: #fff;
                }
                .defaultBtnColor{
                    background-color: #fff;
                    border: 1px solid #E4E4E5;
                    color:#0D1019;
                }
            }
        }
    }
}

.eventActiveChat{
    color: #3EA561;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    font-family: 'twklausannepan-Regular';
    white-space: nowrap;

    .event_text {
        padding-left: 5px;
    }
}

.event_datetime{
    border: 1px solid #E9CFFF !important;
    background-color: #E9CFFF !important;
    padding: 2px 8px !important;
    color: #0D1019 !important;
    font-size: 14px !important; 
    line-height: 20px !important;
    font-family: "twklausannepan-Regular" !important;
    font-weight: 300 !important;
}
.event_stuff{
    border: 1px solid #0D1019 !important;
    color: #0D1019 !important;
    text-transform: capitalize;
    background-color: transparent !important;
    font-size: 14px !important; 
    line-height: 20px !important;
    font-family: "twklausannepan-Regular" !important;
    font-weight: 300 !important;
    padding: 2px 8px !important;
    margin-left: 8px !important;
}

.requiredInput label{
    &::after{
        content: unset !important;
    }    
}

.ant-table-wrapper {
    .ant-table-column-sorters {
        justify-content: flex-start;
    }

    .ant-table-column-title {
        flex: 0;
        white-space: nowrap;
    }

    .ant-table-column-sorter {
        .ant-table-column-sorter-up+.ant-table-column-sorter-down {
            margin-top: -0.1em;
        }
    }
}

.revenue_dropdown {
    // .ant-select-selector {
    //     padding-left: 3px !important;
    // }

    .ant-select-dropdown {
        // .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        //     background-color: transparent;
        // }
        .ant-select-item {
            padding: 6px 12px;
        }
    }
}

.revenue_popup {
    width: 150px !important;
}
.industry_popup {
    width: 290px !important;
}
.location_popup {
    width: 125px !important;
}

.ant-tag {
    margin-inline-end: 0;
}

.default_badge {
    width: 6px;
    height: 6px;
    border-radius: 100%;

    &.grey_badge {
        background-color: #484B53;
    }
    &.yellow_badge  {
        background-color: #FFD96C;
    }
    &.green_badge {
        background-color: #3EA561;
    }
}

.no_data_card {
    width: 100%;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .no_data_image {
        margin-bottom: 24px;
    }

    .no_data_content {
        h4 {
            color: #0D1019;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            font-family: 'twklausannepan-Bold';
            margin-bottom: 8px;
        }

        p {
            color: #484B53;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            font-family: "twklausannepan-Regular";
            margin-bottom: 24px;
        }
    }

    .no_data_app_btn {
        display: flex;
        align-items: center;
        .ant-image {
            padding-left: 12px;
        }
    }
}

.ant-message {
    .ant-message-notice {
        display: flex;
        justify-content: end;

        .ant-message-notice-content {
            background: #0D1019;
            padding: 12px;
            color: #fff;
            border-radius: 8px;

            .ant-message-custom-content {
                >.anticon {
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            
        }

        .toast_text {
            font-family: 'twklausannepan-Bold';
            font-size: 14px;
            line-height: 20px;
        }

        .toast_wrapper {
            display: flex;
            align-items: center;
        }

        .close_icon {
            background-color: transparent;
            width: 16px;
            height: 16px;
            border: 0;
            margin-left: 12px;
            

            &:hover {
                background-color: transparent;
            }
        }
    }

    // .ant-message-notice-success {

    // }

    // .ant-message-info .anticon {
    //     background-color: #021FD6;
    //   }
      
    //   .ant-message-success .anticon {
    //     background-color: #18AD2E;
    //   }
      
    //   .ant-message-error .anticon {
    //     background-color: #D61F56;
    //   }
      
    //   .ant-message-warning .anticon {
    //     background-color: #EF661B;
    //   }
}



.alertWrapper {
    
    .ant-alert {
        margin-bottom: 16px;
        padding: 12px 16px;
        border-radius: 12px;
    
        [class~="ant-alert-close-icon"] [class~="anticon-close"] {
            color: rgba(5, 6, 34, 1);
        }
    }
  
    [class~="ant-alert-message"] {
        color: #050622;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 0;

        +.ant-alert-description {
            margin-top: 8px;
        }
    }
  
    [class~="ant-alert-description"] {
      color: rgba(5, 6, 34, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  
    [class~="ant-alert-with-description"] [class~="ant-alert-icon"] {
      margin-inline-end: 8px;
    }
  
    [class~="ant-alert-info"] {
      background-color: rgba(241, 241, 255, 1);
      border-color: rgba(241, 241, 255, 1);
    }
  
    [class~="ant-alert-error"] {
      background-color: rgba(255, 246, 249, 1);
      border-color: rgba(255, 246, 249, 1);
    }
  
    [class~="ant-alert-success"] {
      background-color: rgba(243, 253, 245, 1);
      border-color: rgba(243, 253, 245, 1);
    }
  
    [class~="ant-alert-warning"] {
      background-color: rgba(254, 248, 236, 1);
      border-color: rgba(254, 248, 236, 1);
    }
  }

  .table_dropdown {
    padding: 0;
    border: 0;
    width: 40px;
    svg {
        path {
            fill: #0D1019
        }
    }
  }

  .ant-tooltip {
    max-width: 230px;
  }

  .ant-select-dropdown {
    z-index: 1;
  }

  .ant-table-wrapper .ant-table-container {
    z-index: 0;
  }

  .empty_card {
    min-height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;


    // .ant-empty-image {
    //     height: auto !important;
    //     .anticon {
    //         svg{
    //             width: 40px;
    //             height: 40px;
    //             margin-bottom: 24px;
    //         }
    //     }
    // }
    h4{
        font-size: 18px;
        font-weight: 500;
        font-family: 'twklausannepan-Medium';
        line-height: 24px;
        color: #0D1019;
        margin-bottom: 8px;
        
    }
    p{
        font-size: 14px;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        line-height: 20px;
        color: #484B53;
        margin-bottom: 24px;
        
    }
}







  .ant-breadcrumb {
    ol {
        li {
            display: flex;
            align-items: center;
            
            a {
                &.ant-breadcrumb-link {
                    font-family: 'twklausannepan-Regular';
                    color: #AEAFB2;
                    &:hover {
                        background-color: transparent;
                        color: #0D1019;
                    }
                }                
            }

            .ant-breadcrumb-link {
                display: flex;
                align-items: center;
                height: auto;
                margin-inline: 0;
                font-size: 14px;
                line-height: 20px;
                color: #0D1019;
                font-family: 'twklausannepan-Bold';

                .anticon {
                    margin-right: 8px;
                }
            }
        }
    }
  }

  .unpublished_tag {
    margin-left: 12px;
    background-color: #727379;
    color: #fff;
    border-radius: 40px;
    padding: 2px 8px;
    border: 0;
    text-transform: capitalize;
  }

  .cancelled_tag {
    margin-left: 12px;
    border-radius: 40px;
    padding: 2px 8px 1px;
    border: 0;
    text-transform: capitalize;
    border: 1px solid #DF2E25;
    color: #DF2E25;
  }

  .published_tag {
    margin-left: 12px;
    background-color: #3EA561;
    color: #fff;
    border-radius: 40px;
    padding: 2px 14px 1px;
    border: 0;
    text-transform: capitalize;
    font-weight: 300;
    font-family: 'twklausannepan-Regular';
    font-size: 14px;
    line-height: 20px;
  }

  .ant-descriptions {
    .ant-descriptions-item-content {
        font-size: 16px;
        line-height: 22px;
        color: #0D1019;
        font-family: "twklausannepan-Regular";
        font-weight: 300;
        margin-bottom: 0;
    
        ul p {
            font-size: 16px;
            line-height: 22px;
            color: #0D1019;
            font-family: "twklausannepan-Regular";
            font-weight: 300;
            margin-bottom: 0;
        }
      }
  }
 

  span.red_color {
    color: #DF2E25;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover,
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover a {
  color: rgba(5, 6, 34, 1);
  background-color: transparent;
}

.ant-pagination .ant-pagination-next a,
.ant-pagination .ant-pagination-prev a {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination .ant-pagination-next svg path,
.ant-pagination .ant-pagination-prev svg path {
  fill: rgba(5, 6, 34, 1)
}

.ant-pagination .ant-pagination-next.ant-pagination-disabled svg path,
.ant-pagination .ant-pagination-prev.ant-pagination-disabled svg path {
  fill: rgba(80, 81, 100, 1)
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover,
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover a {
  color: rgba(5, 6, 34, 1);
  background-color: transparent;
}

.ant-pagination .ant-pagination-item {
  border: 0;
}

.ant-pagination .ant-pagination-item a {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(106, 107, 138, 1);
  padding: 4px;
}

.ant-pagination .ant-pagination-item-active {
  background-color: rgba(5, 6, 34, 1);
}

.ant-pagination .ant-pagination-item-active:hover a,
.ant-pagination .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination .ant-pagination-item-active a{
  padding-bottom: 2px !important;
}

.ant-table-column-sorters {
    .ant-table-column-title {
        padding-right: 4px;
    }
}

.ant-alert-with-description .ant-alert-icon {
    margin-inline-end: 8px;
    margin-bottom: auto;
}

.attendee_modal {
    gap: 0 !important;

    h4 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
    }

    .add_attendee_select {
        .ant-select-selector {
            border: 1px solid #0D1019;
            border-radius: 20px;
            min-height: 40px;
            z-index: 9;
            background-color: transparent;
        }

        &:hover {
            .ant-select-selector {
                border: 1px solid #0D1019;
            }
        }
    }
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: #C287F4;
        border-color: #C287F4;
    }
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #C287F4;
    border-color: transparent;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: #C287F4;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #C287F4;
}

.ant-descriptions {
    .ant-descriptions-item-label {
        &:after {
            display: none;
        }
    }
}

.anticon-check{
    svg{
        path{
            fill: #753CA3;
        }
    }
}

.ant-spin-nested-loading >div>.ant-spin {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A25EDC;
    position: fixed;
}

.ant-spin-nested-loading >div>.ant-spin-lg .ant-spin-dot {
    margin: 0;
    top: auto;
    inset-inline-start: auto;
    position: static;
    font-size: 60px;
}

.ant-spin-nested-loading .ant-spin-container::after {
    height: 100vh;
    position: fixed;
}

.autocomplete-control {
    display: flex;

    .autocomplete-container {
        width: 300px;
    }

    .ant-btn {
        border: 1px solid;
        line-height: 18px;
    }
}

.publish_event {
    &:disabled {
        background-color: transparent;
        color: #949599;
        img{
            opacity: 0.5;
        }
        &:hover{
            background-color: transparent;
            color: #949599 !important;
        }
    }
}