.ant-layout {
    background: transparent;
    .ant-layout-sider {
        &.dashboard_sidebar {
            background: #0D1019;
            position: fixed;
            left: 0;
            top: 0;
            padding: 24px 16px;
            height: 100vh;
            z-index: 9;            
            .sidebar_menu{
                background-color: transparent;
                li.ant-menu-item.ant-menu-item-selected{
                    background: #FFFFFF1F;
                    color: #fff !important;
                    .ant-menu-item-icon{
                        svg{
                            path{
                                fill: #FFD96C;
                            }
                        }
                    }
                }
                li{
                    color: #AEAFB2 !important;
                    padding: 8px 12px !important;
                    margin: 0px 0px 20px 0px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: 'twklausannepan-Medium';
                    &:hover{
                        background: #FFFFFF1F;
                        color: #AEAFB2 !important;
                        .ant-menu-item-icon{
                            svg{
                                path{
                                    fill: #FFD96C;
                                }
                            }
                        }
                    }
                    .ant-menu-item-icon{
                        svg{
                            path{
                                fill: #AEAFB2;
                            }
                        }
                    }
                }
            }
        }

        .sidebar_logo {
            display: flex;
            align-items: center;
            margin-bottom: 37px;
            .logo_bg{
                display: flex;
            }
            .logo_text {
                padding-left: 16px;

                h4 {
                    color: #fff;
                    font-size: 20px;
                    font-family: 'twklausannepan-Bold';
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 2px;
                }

                p {
                    color: #A9AAC1;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: 'twklausannepan-Medium';
                    line-height: 16px;
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }

        .sidebar_footer{
            width: 100%;
            position: absolute;
            left: 0;
            padding: 16px 16px 14px 16px;
            height: 90px;
            background-color: transparent;
            border-top: 1px solid #484B53;
            .user_info{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                .parent{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    .logo_bg{
                        display: flex;
                        border: 1px solid #ccc;
                        border-radius: 100px;
                        background: #ccc;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        img{
                            border-radius: 100px;
                            display: block;
                            object-fit: cover;
                            width: 100%;
                            height: 40px;
                        }
                    }
                    .logo_text {
                        padding-left: 12px;
                        width: calc(100% - 40px);
        
                        h4 {
                            color: #fff;
                            font-size: 16px;
                            font-weight: 300;
                            font-family: 'twklausannepan-Regular';
                            line-height: 22px;
                            margin-bottom: 0px;
                            text-align: left;
                        }
        
                        p {
                            color: #AEAFB2;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 0;
                            font-weight: 300;
                            font-family: 'twklausannepan-Regular';
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: left;
                        }
                    }
                }
                .anticon{
                    svg{
                        width: 15.75px;
                        height: 3px;
                        position: absolute;
                        top: 35px;
                        right: 16px;
                    }
                }
            }
        }
    }
}

// Arete main layout
.main_layout {
    padding-left: 264px;
    background: #fff;

    .content_area {
        padding: 32px 40px;
        .page_title{
            h3{
                color: #0D1019;
                font-size: 24px;
                line-height: 28px;
                font-family: 'twklausannepan-Bold';
                font-weight: 500;
            }
        }

        // Dashboard Quote
        .quote_wapper{
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 20px;
            border: 1px solid #2348F5;
            border-radius: 12px;
            margin-bottom: 20px;
            background-image: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(309.03deg, rgba(214, 31, 177, 0.5) -17.61%, rgba(35, 72, 245, 0.5) 82.06%);
            img{
                margin-right: 12px;
            }
            .quote_text{
                font-size: 18px;
                line-height: 24px;
                
                color: #050622;
                margin-right: 8px;
            }
            .quote_name{
                font-size: 18px;
                line-height: 24px;
                color: #2348F5;
                font-style: italic;
                
            }
        }
        
        // Dashboard cards
        .col_performance_stats,
        .col_cash_status{
            background-color: #F7F8FB;
            border-radius: 16px;
            border: transparent;
        }

        // Dashboard Performance status card
        .col_performance_stats{
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    font-family: 'twklausannepan-Bold';
                    font-weight: 500;
                    
                }
            }
            .dashboard_card_wrapper {
                display: flex;
                align-items: flex-start;
                background-color: #fff;
                padding: 16px;
                border-radius: 12px;
                gap: 12px;
                .dashboard_card_text{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .card_amount{
                        font-size: 20px;
                        line-height: 24px;
                        color: #050622;
                        font-family: 'twklausannepan-Medium';
                        font-weight: 400;
                    }
                    .card_text{
                        font-size: 14px;
                        line-height: 20px;
                        font-family: 'twklausannepan-Medium';
                        font-weight: 400;
                        color: #6A6B8A;
                    }
                }
            }
        }

        // Dashboard Cash status card
        .col_cash_status{
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    font-family: 'twklausannepan-Bold';
                    font-weight: 500;
                    
                }
            }
            .ant-card-body{
                padding-bottom: 20px;
            }
            .cash_status_detail {
                display: flex;
                margin-bottom: 18px;
                .ant-card {
                    border: unset;
                    border-radius: 16px;
                    .ant-card-head{
                        padding: 16px;
                        .ant-card-head-title{
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }
                    }
                    .ant-card-body{
                        padding: 16px;
                        img{
                            height: 6px;
                            width: 6px;
                            margin-top: 10px;
                        }
                    }
                }
                .dashboard_card_wrapper{
                    display: flex;
                    width: 100%;
                    gap: 9px;
                    .dashboard_card_text{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        .card_amount{
                            font-size: 20px;
                            line-height: 24px;
                            color: #050622;
                            font-family: 'twklausannepan-Medium';
                            font-weight: 400;
                        }
                        .card_text{
                            font-size: 14px;
                            line-height: 20px;
                            font-family: 'twklausannepan-Medium';
                            font-weight: 400;
                            color: #6A6B8A;
                        }
                    }

                    .ant-badge-status-dot {
                        border-radius: 2px;
                    }
                }
            }
            .net_balance{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                h3{
                    font-size: 16px;
                    line-height: 20px;
                    font-family: 'twklausannepan-Bold';
                    font-weight: 500;
                }
            }
            .ant-card-head{
                .ant-card-head-wrapper{
                    .ant-card-head-title{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .tag_day{
                            padding: 4px 8px 4px 8px;
                            background-color: #fff;
                            border: unset;
                            border-radius: 6px;
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: 400;
                            font-family: 'twklausannepan-Medium';
                            color: #050622;
                        }
                    }
                    .ant-card-extra{
                        .invoices_and_payouts{
                            font-size: 12px;
                            line-height: 16px;
                            font-family: 'twklausannepan-Bold';
                            font-weight: 500;
                            color: #050622;
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            &:hover{
                                color: #050622;
                            }
                        }
                    }
                }
            }
        }

        // Dashboard Tabs
        .dashboard_Tabs_wrapper {
            padding: 24px 20px;
            border: 1px solid #EEEEF5;
            border-radius: 20px;
            .ant-tabs-content-holder{
                height: 288px;
                overflow: auto;
                .ant-list-item{
                    padding-top: 16px;
                    padding-bottom: 16px;
                    border-block-end: 1px solid #EEEEF5 !important;
                    .ant-list-item-meta{
                        .ant-list-item-meta-avatar{
                            .ant-avatar-image{
                                width: 36px;
                                height: 36px;
                            }
                        }
                        .ant-list-item-meta-content{
                            .ant-list-item-meta-title{
                                
                                font-weight: 500;
                                color: #050622;
                                a{
                                    font-family: 'twklausannepan-Bold';
                                    font-weight: 500;
                                    color: #050622;
                                    &:hover{
                                        color: #050622;
                                    }
                                }
                            }
                            .ant-list-item-meta-description{
                                color: #050622;
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400;
                                font-family: 'twklausannepan-Medium';                                
                            }
                        }
                    }
                    .notification_time{
                        font-size: 12px;
                        line-height: 16px;
                        font-family: 'twklausannepan-Bold';
                        font-weight: 500;
                        color: #6A6B8A;
                        
                    }
                }
                .ant-tabs-content{
                    .ant-col{
                        padding-left: 0px !important;
                        padding-right: 20px !important;
                    }
                }
            }
            .ant-tabs-nav-wrap{
                .ant-tabs-nav-list{
                    background-color: rgb(247, 248, 251);
                    width: 90%;
                    border-radius: 8px;
                    padding: 2px;
                    border: unset;
                    margin-left: auto;
                    .ant-tabs-tab{
                        margin: 0;
                        padding: 8px 12px;
                        width: 140px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .ant-tabs-tab-btn{
                            color: #6A6B8A;
                            font-family: 'twklausannepan-Bold';
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            text-shadow: unset;
                        }
                    }
                    .ant-tabs-tab-active{
                        background-color: #fff;
                        border-radius: 8px;
                        width: 140px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 12px;                        
                        .ant-tabs-tab-btn{
                            color: #050622;
                            font-family: 'twklausannepan-Bold';
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            text-shadow: unset;
                        }
                    }
                }
            }
            .tabs_title{
                position: absolute;
                top: 31px;
                font-size: 22px;
                font-family: 'twklausannepan-Bold';
                font-weight: 500;
            }
            .ant-tabs{
                .ant-tabs-nav-more{
                    display: none;
                }
                .ant-tabs-nav{
                    &::before{
                        border-bottom: unset !important;
                        content: '';
                    }
                }
            }
        }    

        // Dashboard Outstanding Table
        .outstanding_table{
            border: 1px solid #EEEEF5;
            border-radius: 20px;
            .title_bar{
                width: 100%;
                padding: 22px 24px 22px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4{
                    font-size: 18px;
                    line-height: 24px;
                    
                }
                .see_all_360_assessments{
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    color: #050622;
                    font-size: 12px;
                    line-height: 16px;
                    
                }
            }
            .dashboard_table {
                .ant-table-content{
                    .ant-table-thead{
                        tr{
                            border-radius: 12px;
                            margin-bottom: 8px;
                            th{
                                border: unset;
                                font-size: 14px;
                                line-height: 20px;
                                font-family: 'twklausannepan-Medium';
                                font-weight: 400;
                                color: #6A6B8A;
                            }
                        }
                    }
                    .ant-table-tbody{
                        tr{
                            padding: 14px 12px;
                            td{
                                font-size: 16px;
                                line-height: 22px;
                                font-family: 'twklausannepan-Medium';
                                font-weight: 400;
                                color: #0A0B2D;
                                .table_with_image{
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                }
                                .status_b2b{
                                    background-color: #9B1FD6;
                                    border-radius: 20px;
                                    border: unset;
                                    padding: 2px 8px;
                                    color: #fff;
                                    
                                }
                                .status_b2c{
                                    background-color: #b258db;
                                    border-radius: 20px;
                                    border: unset;
                                    padding: 2px 8px;
                                    color: #fff;
                                    
                                }
                                .rate_success{
                                    border: 1px solid  #F9BB9A;
                                    padding: 2px 8px;
                                    border-radius: 20px;
                                    color: #EF661B;
                                    
                                    font-size: 12px;
                                }
                                .rate_alert{
                                    border: 1px solid #9CE4A6;
                                    padding: 2px 8px;
                                    border-radius: 20px;
                                    color: #18AD2E;
                                    
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Dashboard Operation Tasks Table
        .table_wrapper{
            border: 1px solid #EEEEF5;
            border-radius: 20px;
            padding: 22px 24px 22px 24px;
            .title_bar{
                width: 100%;
                margin-bottom: 22px;
                h4{
                    font-size: 18px;
                    line-height: 24px;
                    
                }
            }
            .dashboard_table {
                .ant-table-content{
                    .ant-table-thead{
                        tr{
                            border-radius: 12px;
                            margin-bottom: 8px;
                            th{
                                border: unset;
                                font-size: 14px;
                                line-height: 20px;
                                font-family: 'twklausannepan-Medium';
                                font-weight: 400;
                                color: #6A6B8A;
                            }
                        }
                    }
                    .ant-table-tbody{
                        tr{
                            padding: 14px 12px;
                            td{
                                font-size: 16px;
                                line-height: 22px;
                                font-family: 'twklausannepan-Medium';
                                font-weight: 400;
                                color: #0A0B2D;
                                .table_with_image{
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                }
                                .ant-avatar-group{
                                    display: flex;
                                    align-items: center;
                                    .ant-avatar-lg{
                                        height: 24px;
                                        width: 24px;
                                    }
                                }
                                .status_not_started{
                                    background-color: #6A6B8A;
                                    border: 1px solid #6A6B8A;
                                    border-radius: 10px;
                                    padding: 2px 8px;
                                    color: #fff;
                                    width: 100px;
                                    font-size: 12px;
                                    line-height: 16px;
                                    font-family: 'twklausannepan-Bold';
                                    font-weight: 500;
                                    text-align: center;
                                }
                                .status_started{
                                    background-color: #18AD2E;
                                    border: 1px solid #18AD2E;
                                    border-radius: 10px;
                                    padding: 2px 8px;
                                    color: #fff;
                                    width: 100px;
                                    font-size: 12px;
                                    line-height: 16px;
                                    font-family: 'twklausannepan-Bold';
                                    font-weight: 500;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

