.ant-input-outlined,
.ant-picker-outlined {
    &.formControl {
        border: 1px solid #0D1019;
        border-radius: 20px;
        padding: 10px 16px;
        height: 40px;
        width: 100%;

        &::placeholder {
            color: #727379;
            font-size: 14px;
            font-weight: 300;
            font-family: "twklausannepan-Regular";
            line-height: 20px !important;
        }
    }
}

.ant-select {
    .ant-select-selection-placeholder {
        color: #727379;
        font-size: 14px;
        font-weight: 300;
        font-family: "twklausannepan-Regular";
        line-height: 20px !important;
    }
}

.ant-input-password {
    &.formControl {
        input {
            border: 0;
            padding: 0;
            height: auto;

            &::placeholder {
                color: #727379;
                font-size: 14px;
                font-weight: 300;
                font-family: "twklausannepan-Regular";
                line-height: 20px !important;
            }
        }
    }
    .ant-input-suffix{
        .ant-input-password-icon{
            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
}

textarea {
    &.ant-input-outlined {
        &.formControl {
            resize: none;
            height: 96px;
        }
    }
}

.ant-form {
    .ant-form-item:not(.ant-form-item-horizontal) {
        .ant-form-item-label {
            padding-bottom: 2px;
        }
    }
}

.location_image {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .upload_avatar {
        width: 88px;
        height: 88px;
        background-color: #F1F2F2;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;

        img {
            border-radius: 12px;
            object-fit: cover;
        }
    }

    .format_text {
        color: #484B53;
        font-size: 12px;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        line-height: 14px;
        margin-top: 12px;
    }
}

.upload_loc_image {
    display: flex;
    align-items: center;
}

.circle_btn {
    width: 40px;
    height: 40px;
    padding: 0;
    background: transparent;
    display: block;
    border: 0;

    &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: transparent;
    }

    img {
        display: block;
    }
}

.memberDetailCard {
    background-color: #F7F7F7;
    border: 1px solid #F7F7F7;
    border-radius: 16px;
    
    .ant-card-body {
        padding: 20px;
    }

    .red_outline_tag {
        margin-left: 10px;
        font-weight: 500;
        font-family: 'twklausannepan-Medium';
    }

    .ant-tag {
        +.ant-tag {
            margin-left: 4px;
        }    
    }
}

.deactivatedCard {
    background-color: #FDF2F2;
    border: 1px solid #FDF2F2;
    border-radius: 16px;

    .ant-card-meta-avatar {
        padding-inline-end: 8px;
        margin-top: -2px;
    }
    
    .ant-card-body {
        padding: 12px 16px;
    }

    .ant-card-meta-detail {
        .ant-card-meta-title {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            font-family: 'twklausannepan-Bold';
            margin-bottom: 4px !important;
        }
    
        .ant-card-meta-description {
            font-size: 14px;
            line-height: 20px;
            color: #0D1019;
            margin-bottom: 0;
        }
    }
}

.members_detail_left_card {
    background-color: #F8F8F9;
    border: 1px solid #F8F8F9;
    padding: 20px;
    .eventsDetailTitle{
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'twklausannepan-Bold';
        margin-bottom: 12px;
        color: #0D1019;
    }
    .events_host_detail{
        background-color: #fff;
        padding: 12px;
        border-radius: 8px;
        margin-bottom: 12px;

        .ant-card-meta-avatar{
            height: 48px;
            width: 48px;
        }
        .ant-card-meta-detail{
            margin-left: 8px;
            position: relative;
            .ant-card-meta-title{
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 4px;
                font-weight: 500;
                font-family: 'twklausannepan-Bold';
                color: #727379;
            }
            .ant-card-meta-description{
                font-size: 16px;
                line-height: 20px;
                font-weight: 300;
                font-family: 'twklausannepan-Regular';
                color: #0D1019;
                text-transform: capitalize;
            }

            .arrow_btn {
                position: absolute;
                right: -8px;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 40px;
            }
        }
    }
    .ant-card-body {
        padding: 0px;
        .ant-descriptions-header{
            margin-bottom: 20px;
            .ant-descriptions-title{
                font-size: 20px;
                line-height: 24px;
                font-family: 'twklausannepan-Bold';
                font-weight: 500;
            }
        }
        .ant-descriptions-item{
            padding-bottom: 4px;
            .ant-descriptions-item-label{
                color: #A25EDC;
            }
            ul{
                padding-left: 20px;
            }
        }
    }
    table{
        tbody{
            tr{
                &:first-child {
                    th {
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
                th{
                    &:first-child{
                        padding-bottom: 4px !important;
                        padding-top: 8px;
                    }
                }
            }
        }
    }
}


.ant-form-item {
    .ant-form-item-label {
        >label {
            color: #0D1019;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            font-family: "twklausannepan-Regular";

            &::before {
                display: none !important;
            }

            &.ant-form-item-required {
                &::after {
                    visibility: visible !important;
                    display: inline-block;
                    margin-inline-end: 2px;
                    color: #DF2E25;
                    font-size: 16px;
                    line-height: 20px;
                    font-family: 'twklausannepan-Bold';
                    font-weight: 500;
                    content: "*";
                }
            }
        }
    }
}

.cancelation_policy_text {
    textarea {
        .formControl {
            color: #0D1019;
            font-size: 14x;
            line-height: 20px;
            font-family: 'twklausannepan-Regular';
        }
    }
}

.add_attendee_select  {
    height: auto;

    &.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
        padding-inline-end: 70px;
        padding-inline-start: 8px;
        .ant-select-selection-item{
            background-color: #fff;
            border: 1px solid #F1F2F2;
            border-radius: 40px;
        }
    }
}

.add_attendee_select {
    &.ant-select-multiple .ant-select-selection-search {
        margin-inline-start: 0
    }
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-7vs16m).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-7vs16m).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: #0D1019;
}

.ant-picker-ranges .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-picker-ranges .ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
    background: #0D1019;
}

