.mainLogin {
    display: flex;
  }
  
  .twoColumn {
    display: flex;
    width: 100%;
  }
  
  .leftLogin {
    width: 52%;
    padding: 40px 141px 40px 161px;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
    .loginWrapper {
      max-width: 464px;
      width: 100%;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .loginLogo {
      margin-bottom: 8px;
    }
  
    .loginForm {
      width: 100%;
  
      h1 {
        color: #0D1019;
        font-weight: 300;
        font-family: 'twklausannepan-Regular';
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
        margin-top: 0;
      }
  
      p {
        color: #484B53;
        font-weight: 300;
        font-family: "twklausannepan-Regular";
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 48px;
        margin-top: 0;

        +.alertWrapper .ant-alert {
          margin-top: -32px;
        }
      }  

      .ant-form{
        .ant-form-item{
          &:nth-child(2){
            margin-bottom: 8px !important;
          }
        }
      }
    }
  
    .forgotPass {
      // margin-top: -20px;
      margin-bottom: 24px;
  
      a {
        padding: 10px 8px;
        color: #0D1019;
        font-weight: 400;
        font-family: 'twklausannepan-Medium';
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
      }
    }
  
    .cantLogin {
      margin-top: 24px;
  
      p {
        color: #6A6B8A;
        font-weight: 300;
        font-family: "twklausannepan-Regular";
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0px;
      }
  
      a {
        color: #0D1019;
        text-decoration: underline;
        font-weight: 400;
        font-family: 'twklausannepan-Medium';

      }
    }
    .loginBtn{
      color: #fff;
      background: #0D1019;
      box-shadow: unset;
      padding: 10px 16px;
      border-radius: 24px;
      height: 40px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'twklausannepan-Medium';
      &:hover{
        background-color: #0D1019 !important;
        color: #fff !important;
      }
    }
  }
  
  .rightLogin {
    width: 48%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #E9CFFF;
    padding: 0 85px;
    background-position: top right;
    background-size: cover;
    
    .right_wrapper{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .loginVector {
      position: absolute;
      left: 277px;
      width: 975px;
      height: 975px;
      object-fit: cover;
    }
  
    .loginImage {
      .ant-image {
        height: 100%;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  
  .quoteWrapper {
    padding-top: 50px;
    max-width: 516px;
    display: flex;
  
    .quoteText {  
      h3 {
        color: #0D1019;
        font-weight: 400;
        font-family: 'twklausannepan-Medium';
        font-size: 24px;
        margin-bottom: 16px;
        margin-top: 0;
      }
  
      p {
        color: #484B53;
        font-weight: 400;
        font-family: 'twklausannepan-Medium';
        font-size: 18px;
      }
    }
  }