.event_form{
    .divider {
        border: 1px solid #E4E4E5;
        width: 100%;
        height: 1px;
        margin: 0 auto;
        margin-bottom: 24px;
    }
    .center_all{
        border: 1px solid #F1F2F2;
        border-radius: 24px;
        background-color: #fff;
        .center_head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            div{
                .preview_event{
                    border: 1px solid #E4E4E5;
                    border-radius: 24px;
                    padding: 10px;
                    width: 184px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .location_image {
        width: 343px;
        height: 300px;
        .upload_avatar {
            width: 100%;
            height: 100%;
            img{
                object-fit: cover;
            }
        }
    }
    .inputNumberFld{
        .ant-form-item-control-input{
            .ant-input-number{
                width: 100%;
                border: unset;
                &:active,
                &:focus,
                &:hover{
                    border: transparent;
                    border-radius: 20px;
                }
                .ant-input-number-handler-wrap{
                    visibility: hidden;
                }
                .ant-input-number-input{
                    border: 1px solid #0D1019;
                    border-radius: 20px;
                    padding: 10px 16px;
                    height: 40px;
                    width: 100%;
                }

                &.ant-input-number-status-error {
                    .ant-input-number-input{
                        border: 1px solid #ff4d4f;
                    }
                }
            }
        }
    }


    .fundingSource_radio{
        .ant-form-item-control{
            .ant-form-item-control-input{
                .ant-radio-group{
                    height: 40px;
                    background-color: #F1F2F2;
                    border-radius: 24px;
                    display: flex;
                    width: 100%;
                    .ant-radio-button-wrapper:not(:first-child)::before{
                        content: "";
                        background-color: unset;
                    }
                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                        border-radius: 24px;
                        color: #fff;
                        background-color: #0D1019;
                        border: 1px solid #0D1019;
                        width: 100%;
                        text-align: center;
                        .ant-radio-button-checked{
                            background-color: #0D1019;
                            border: 1px solid #0D1019;
                            color: #fff;
                            border-radius: 24px;
                        }
                    }
                    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked){
                        background-color: #F1F2F2;
                        border: 1px solid #F1F2F2;
                        color: #6A6B8A;
                        width: 100%;
                        text-align: center;
                    }
                    .ant-radio-button-wrapper{
                        height: 40px;
                        border-radius: 24px;
                        span{
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }
    }
    .ant-form-item{
        margin-bottom: 12px;
    }
}

.venue_address {
    overflow: hidden;
    margin-bottom: 12px;

    h4 {
        color: #0D1019;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        font-family: "twklausannepan-Regular";

        span {
            margin-inline-end: 2px;
            color: #DF2E25;
            font-size: 16px;
            line-height: 20px;
            font-family: "twklausannepan-Bold";
            font-weight: 500;
        }
    }

    button {
        border: 1px solid #0D1019;
        border-radius: 20px;
        padding: 10px 16px;
        height: 40px;
        width: 100%;
        color: #727379;
        font-weight: 300;

        &.error_venue {
            border: 1px solid #ff4d4f;
        }

        &.valueButton {
            color: #0D1019;

            &:hover {
                color: #0D1019 !important;
            }
        }

        span {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;
        }

        &:hover {
            border: 1px solid #0D1019 !important;
            color: #727379 !important;
        }
    }
}

.venueAddressMap {
    .ant-modal {
        .ant-modal-content {
            overflow: hidden;
        }

        
    }
}

.modal_header {
    padding: 10px 10px 10px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
        color: #0D1019;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        font-family: "twklausannepan-Bold";
    }
}

.autocomplete-control {
    input {
        background: #fff;
        border: 1px solid #0D1019;
        border-radius: 20px;
        padding: 10px 16px;
        height: 40px;
        width: 300px;

        &::placeholder {
            color: #727379;
            font-size: 14px;
            font-weight: 300;
            font-family: "twklausannepan-Regular";
            line-height: 20px !important;
        }
    }
}

.autocomplete-container {
    .custom-list {
        border-radius: 8px;
        padding: 8px;
        li {
            list-style: none;
            padding: 3px;
            font-size: 12px;
            cursor: pointer;

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }
}