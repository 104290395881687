.only_mobile {
    display: none;
}

@media screen and (max-width: 1399px) {
    .leftLogin {
        padding: 40px 120px;

        .loginForm {
            h1 {
                font-size: 23px;
                line-height: 30px;
            }

            p {
                margin-bottom: 32px;
                font-size: 15px;
            }

            .ant-input-outlined.formControl, 
            .ant-picker-outlined.formControl {
                height: 35px;
            }

            .ant-input-password.formControl {
                input {
                    &::placeholder {
                        font-size: 13px;
                    }
                }
            }
        }

        .loginBtn {
            height: 35px;
        }

        .loginLogo {
            .ant-image {
                img {
                    height: 40px !important;
                }
            }
        }
    }

    .eventsLeftCard {
        width: 30%;
        flex: none !important;
    }

    .eventsRightCard {
        width: 70%;
        flex: none !important;
    }

    .mainLogin {
        .leftLogin {
            // styles are already included above
        }
    }

    .icon_dark_btn {
        height: 36px;
    }

    .ant-table-wrapper {
        .ant-table-tbody > tr > td {
            font-size: 13px;
        }
    }

    .defaultTag {
        font-size: 12px !important;
    }

    .memberDetailLeft {
        flex: 0 0 320px !important;
    }

    .ant-card-meta-detail {
        .ant-card-meta-title {
            font-size: 20px;
            text-transform: capitalize;
        }
    }

    .members_detail_left_card {
        .ant-card-body {
            .ant-descriptions-header {
                .ant-descriptions-title {
                    font-size: 18px;
                }
            }
        }
    }

    .member_company_detail {
        h4 {
            font-size: 18px;
        }

        p {
            font-size: 13px;
        }
    }

    .event_reservations {
        h4 {
            font-size: 18px;
        }
    }

    .no_data_card {
        .no_data_image {
            margin-bottom: 10px;

            img {
                width: 160px;
            }
        }

        .no_data_content {
            h4 {
                font-size: 18px;
            }
        }
    }

    .eventCard {
        .event_details {
            h4 {
                a {
                    font-size: 16px;
                }
            }
        }

        .event_amount {
            font-size: 16px;
        }

        .event_date {
            .dateFld {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .event_details {
            .event_loc_cat {
                .ant-tag {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
    .search_filter {
        width: 190px;
    }
    .eventCard.upcoming {
        .event_date {
            .dateFld {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    .leftTabCard {
        .cardHead{
            h3{
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    .ant-tabs.ant-tabs-left {
        .ant-tabs-nav {
            .ant-tabs-tab{
                padding: 8px 10px;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }
    .ant-tabs.ant-tabs-left {
        .ant-tabs-nav{
            width: 140px;
        }
    }
    .table_with_image {
        .main_text{
            font-size: 13px;
            text-transform: capitalize;
        }
    }
    .ant-table-wrapper {
        .ant-table-tbody > tr > td{
            padding: 10px;
        }
    }
}

@media screen and (max-width: 720px) {
    .rightLogin {
        display: none;
    }

    .leftLogin {
        width: 100%;
        padding: 135px 16px;
    }

    .login_mobile {
        display: none;
    }

    .only_mobile {
        display: block;

        .alertWrapper {
            .ant-alert-icon {
                margin-bottom: auto;
            }
        }
    }

    .open_app_btn {
        font-family: 'twklausannepan-Bold';
        background: #FFD96C;
        border-radius: 40px;
        text-align: center;
        color: #0D1019;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        height: 40px;
    }

    .center_login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100vh - 270px);
    }
}
